<template>
  <div class="vertical-box-column">
    <div class="vertical-box">
      <div class="wrapper">
        <div class="btn-toolbar">
          <div class="btn-group btn-block m-r-5">
            <a
              href="javascript:;"
              class="btn btn-default btn-sm p-l-auto p-r-auto"
              style="font-weight: bold"
            >
              Data Imunisasi
            </a>
          </div>
        </div>
      </div>

      <div class="vertical-box-row bg-white">
        <div class="vertical-box-cell">
          <div class="vertical-box-inner-cell">
            <vue-custom-scrollbar class="height-full">
              <form
                id="myForm"
                @submit.prevent="postData"
                enctype="multipart/form-data"
              >
                <div class="container mt-3">
                  <div class="col-md-12">
                    <input
                      type="text"
                      hidden
                      v-model="form.id_imunisasi"
                      name="id_imunisasi"
                    />
                    <input
                      type="text"
                      hidden
                      v-model="form.kode_imunisasi"
                      name="kode_imunisasi"
                    />
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label"
                      for="tanggal_imunisasi"
                      >Tanggal Imunisasi</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.tanggal_imunisasi"
                        type="date"
                        class="form-control"
                        name="tanggal_imunisasi"
                        :class="{
                          'is-invalid': formValidate.tanggal_imunisasi,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="nama_bayi"
                      >Nama Bayi</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.nama_bayi"
                        type="text"
                        class="form-control"
                        name="nama_bayi"
                        placeholder="Nama Bayi"
                        :class="{
                          'is-invalid': formValidate.nama_bayi,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="tanggal_lahir"
                      >Tanggal Lahir</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.tanggal_lahir"
                        type="date"
                        class="form-control"
                        name="tanggal_lahir"
                        :class="{
                          'is-invalid': formValidate.tanggal_lahir,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="nama_ibu"
                      >Nama Ibu</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.nama_ibu"
                        type="text"
                        class="form-control"
                        name="nama_ibu"
                        placeholder="Nama Ibu"
                        :class="{
                          'is-invalid': formValidate.nama_ibu,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Kecamatan</label>
                    <div class="col-md-3">
                      <div class="input-group">
                        <select
                          v-model="form.kecamatan"
                          class="form-control"
                          name="kecamatan"
                          :class="{
                            'is-invalid': formValidate.kecamatan,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="Palu Timur">Palu Timur</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Kelurahan</label>
                    <div class="col-md-3">
                      <div class="input-group">
                        <select
                          v-model="form.kelurahan"
                          class="form-control"
                          name="kelurahan"
                          :class="{
                            'is-invalid': formValidate.kelurahan,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="Siranindi">Siranindi</option>
                        </select>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="alamat"
                      >Alamat</label
                    >
                    <div class="col-md-8">
                      <input
                        v-model="form.alamat"
                        type="text"
                        class="form-control"
                        name="alamat"
                        placeholder="Alamat Lengkap"
                        :class="{
                          'is-invalid': formValidate.alamat,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4"
                      >Jenis Imunisasi</label
                    >
                    <div class="col-md-3">
                      <div class="input-group">
                        <select
                          v-model="form.jenis_imunisasi"
                          class="form-control"
                          name="jenis_imunisasi"
                          :class="{
                            'is-invalid': formValidate.jenis_imunisasi,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="Polio">Polio</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </vue-custom-scrollbar>
          </div>
        </div>
      </div>

      <div class="wrapper clearfix">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn btn-teal"
            @click="postData()"
            :disabled="this.loading"
          >
            <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
            {{ this.actionBtn }}
          </button>
        </div>
        <div class="m-t-5 text-inverse f-w-600">
          <button type="submit" class="btn btn-default" @click="resetForm()">
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_imunisasi: "",
        kode_imunisasi: "",
        tanggal_imunisasi: "",
        nama_bayi: "",
        tanggal_lahir: "",
        nama_ibu: "",
        kecamatan: "",
        kelurahan: "",
        alamat: "",
        jenis_imunisasi: "",
      },
      link: "/api/imunisasi/create",
      formValidate: [],
      loading: false,
      actionBtn: "Simpan",
      // detail: "",
    };
  },
  props: {
    detail: {
      type: Object,
    },
    editForm: {
      type: Boolean,
    },
  },
  mounted() {
    if (this.editForm) {
      this.form = { ...this.detail };
      this.link = "/api/imunisasi/update";
      this.actionBtn = "Update";
    } else {
      this.resetForm();
    }
  },
  created() {
    this.$parent.$on("openForm", this.openFormHandler);
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            // console.log(this.formValidate);
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.resetForm();
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    resetForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.formValidate = [];
      this.link = "/api/imunisasi/create";
      this.loading = false;
      this.actionBtn = "Simpan";
    },
  },
};
</script>