<template>
  <div class="vertical-box-column">
    <div class="vertical-box">
      <div class="wrapper">
        <div class="btn-toolbar">
          <div class="btn-group btn-block m-r-5">
            <a
              href="javascript:;"
              class="btn btn-default btn-sm p-l-auto p-r-auto"
              style="font-weight: bold"
            >
              Data Kelahiran Di Puskesmas
            </a>
          </div>
        </div>
      </div>

      <div class="vertical-box-row bg-white">
        <div class="vertical-box-cell">
          <div class="vertical-box-inner-cell">
            <vue-custom-scrollbar class="height-full">
              <form
                id="myForm"
                @submit.prevent="postData"
                enctype="multipart/form-data"
              >
                <div class="container mt-3">
                  <div class="col-md-12">
                    <input
                      type="text"
                      hidden
                      v-model="form.id_kelahiran"
                      name="id_kelahiran"
                    />
                    <input
                      type="text"
                      hidden
                      v-model="form.kode_kelahiran"
                      name="kode_kelahiran"
                    />
                  </div>
                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="nama_bayi"
                      >Nama Bayi</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.nama_bayi"
                        type="text"
                        class="form-control"
                        name="nama_bayi"
                        placeholder="Nama Bayi"
                        :class="{
                          'is-invalid': formValidate.nama_bayi,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="jenis_kelamin"
                      >Jenis Kelamin</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.jenis_kelamin"
                        name="jenis_kelamin"
                        class="form-control"
                        :class="{
                          'is-invalid': formValidate.jenis_kelamin,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option value="Laki-Laki">Laki-Laki</option>
                        <option value="Perempuan">Perempuan</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="nama_ayah"
                      >Nama Ayah</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.nama_ayah"
                        type="text"
                        class="form-control"
                        name="nama_ayah"
                        placeholder="Nama Ayah"
                        :class="{
                          'is-invalid': formValidate.nama_ayah,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="nama_ibu"
                      >Nama Ibu</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.nama_ibu"
                        type="text"
                        class="form-control"
                        name="nama_ibu"
                        placeholder="Nama Ibu"
                        :class="{
                          'is-invalid': formValidate.nama_ibu,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="no_kk"
                      >No. KK</label
                    >
                    <div class="col-md-3">
                      <input
                        v-model="form.no_kk"
                        type="text"
                        class="form-control"
                        name="no_kk"
                        placeholder="No. KK"
                        :class="{
                          'is-invalid': formValidate.no_kk,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="provinsi"
                      >Provinsi</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.provinsi"
                        class="form-control default-select2"
                        name="provinsi"
                        v-on:change="getKabupaten($event)"
                        :class="{
                          'is-invalid': formValidate.provinsi,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="provinsi in opsiProvinsi"
                          :key="provinsi.nama_provinsi"
                          :value="provinsi.nama_provinsi"
                        >
                          {{ provinsi.nama_provinsi }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="kabupaten"
                      >Kabupaten</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.kabupaten"
                        name="kabupaten"
                        class="form-control m-b-5"
                        v-on:change="getKecamatan($event)"
                        :class="{
                          'is-invalid': formValidate.kabupaten,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="kabupaten in opsiKabupaten"
                          :key="kabupaten.nama_kabupaten"
                          :value="kabupaten.nama_kabupaten"
                        >
                          {{ kabupaten.nama_kabupaten }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="kecamatan"
                      >Kecamatan</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.kecamatan"
                        name="kecamatan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.kecamatan,
                        }"
                        v-on:change="getKelurahan($event)"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="kecamatan in opsiKecamatan"
                          :key="kecamatan.nama_kecamatan"
                          :value="kecamatan.nama_kecamatan"
                        >
                          {{ kecamatan.nama_kecamatan }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="kelurahan"
                      >Kelurahan</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.kelurahan"
                        name="kelurahan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.kelurahan,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="kelurahan in opsiKelurahan"
                          :key="kelurahan.nama_kelurahan"
                          :value="kelurahan.nama_kelurahan"
                        >
                          {{ kelurahan.nama_kelurahan }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label">RT/RW</label>
                    <div class="col-md-3">
                      <div class="input-group">
                        <input
                          v-model="form.rt"
                          type="text"
                          name="rt"
                          class="form-control"
                          placeholder="RT"
                          style="text-align: center"
                          :class="{
                            'is-invalid': formValidate.rt,
                          }"
                        />
                        <input
                          v-model="form.rw"
                          type="text"
                          name="rw"
                          class="form-control"
                          placeholder="RW"
                          style="text-align: center"
                          :class="{
                            'is-invalid': formValidate.rw,
                          }"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Nama Jalan</label>
                    <div class="col-md-8">
                      <input
                        v-model="form.nama_jalan"
                        name="nama_jalan"
                        type="text"
                        class="form-control m-b-5"
                        placeholder="Nama Jalan"
                        :class="{
                          'is-invalid': formValidate.nama_jalan,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Nomor Rumah</label>
                    <div class="col-md-3">
                      <input
                        v-model="form.nomor_rumah"
                        name="nomor_rumah"
                        type="text"
                        class="form-control m-b-5"
                        placeholder="Nomor Rumah"
                        style="text-align: center"
                        :class="{
                          'is-invalid': formValidate.nomor_rumah,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Tanggal Lahir</label>
                    <div class="col-md-3">
                      <input
                        v-model="form.tanggal_lahir"
                        name="tanggal_lahir"
                        type="date"
                        class="form-control m-b-5"
                        placeholder="Tanggal Lahir"
                        :class="{
                          'is-invalid': formValidate.tanggal_lahir,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Jam Lahir</label>
                    <div class="col-md-3">
                      <input
                        v-model="form.jam_lahir"
                        name="jam_lahir"
                        type="time"
                        class="form-control m-b-5"
                        placeholder="Jam Lahir"
                        :class="{
                          'is-invalid': formValidate.jam_lahir,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4"
                      >Umur Kehamilan Saat Lahir</label
                    >
                    <div class="col-md-3">
                      <div class="input-group">
                        <input
                          v-model="form.bulan_kehamilan"
                          type="text"
                          name="bulan_kehamilan"
                          class="form-control"
                          placeholder="Bulan"
                          style="text-align: center"
                          :class="{
                            'is-invalid': formValidate.bulan_kehamilan,
                          }"
                        />
                        <span class="input-group-append">
                          <span class="input-group-text">Bulan</span>
                        </span>
                        <input
                          v-model="form.hari_kehamilan"
                          type="text"
                          name="hari_kehamilan"
                          class="form-control"
                          placeholder="Hari"
                          style="text-align: center"
                          :class="{
                            'is-invalid': formValidate.hari_kehamilan,
                          }"
                        />
                        <span class="input-group-append">
                          <span class="input-group-text">Hari</span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Berat Badan</label>
                    <div class="col-md-3">
                      <div class="input-group">
                        <input
                          v-model="form.berat_badan"
                          type="text"
                          name="berat_badan"
                          class="form-control"
                          placeholder="Berat Badan"
                          style="text-align: center"
                          :class="{
                            'is-invalid': formValidate.berat_badan,
                          }"
                        />
                        <span class="input-group-append">
                          <span class="input-group-text">Kg</span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Tinggi Badan</label>
                    <div class="col-md-3">
                      <div class="input-group">
                        <input
                          v-model="form.tinggi_badan"
                          type="text"
                          name="tinggi_badan"
                          class="form-control"
                          placeholder="Tinggi Badan"
                          style="text-align: center"
                          :class="{
                            'is-invalid': formValidate.tinggi_badan,
                          }"
                        />
                        <span class="input-group-append">
                          <span class="input-group-text">cm</span>
                        </span>
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Normal/Rujuk</label>
                    <div class="col-md-3">
                      <div class="input-group">
                        <select
                          v-model="form.normal_rujuk"
                          class="form-control"
                          name="normal_rujuk"
                          :class="{
                            'is-invalid': formValidate.normal_rujuk,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="Normal">Normal</option>
                          <option value="Rujuk">Rujuk</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </vue-custom-scrollbar>
          </div>
        </div>
      </div>

      <div class="wrapper clearfix">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn btn-teal"
            @click="postData()"
            :disabled="this.loading"
          >
            <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
            {{ this.actionBtn }}
          </button>

          <!-- <button type="submit" class="btn btn-teal btn-block">
            <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
            Simpan
          </button> -->
        </div>
        <div class="m-t-5 text-inverse f-w-600">
          <button type="submit" class="btn btn-default" @click="resetForm()">
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";
// import eventBus from "../../../plugins/eventBus";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_kelahiran: "",
        kode_kelahiran: "",
        nama_bayi: "",
        jenis_kelamin: "",
        nama_ayah: "",
        nama_ibu: "",
        no_kk: "",
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        rt: "",
        rw: "",
        nama_jalan: "",
        nomor_rumah: "",
        tanggal_lahir: "",
        jam_lahir: "",
        bulan_kehamilan: "",
        hari_kehamilan: "",
        berat_badan: "",
        tinggi_badan: "",
        normal_rujuk: "",
      },
      link: "/api/kelahiran/create",
      opsiProvinsi: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      formValidate: [],
      loading: false,
      actionBtn: "Simpan",
      // detail: "",
    };
  },
  props: {
    detail: {
      type: Object,
    },
    editForm: {
      type: Boolean,
    },
  },
  mounted() {
    if (this.editForm) {
      this.form = { ...this.detail };
      this.getOption();
      this.link = "/api/kelahiran/update";
      this.actionBtn = "Update";
    } else {
      this.resetForm();
    }
    this.getProvinsi();
  },
  created() {
    this.$parent.$on("openForm", this.openFormHandler);
  },
  methods: {
    // openFormHandler(detail) {
    //   this.detail = detail;
    //   console.log(detail);
    //   this.openForm = true;
    // },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      // const formElement = document.getElementById("myForm");
      // const formData = new FormData(formElement);
      // console.log(this.form);

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.resetForm();
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getProvinsi() {
      axios
        .get("/api/daerah/provinsi")
        .then((response) => {
          this.opsiProvinsi = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten(event) {
      this.form.provinsi = event.target.value;
      axios
        .get("/api/daerah/kabupaten/" + event.target.value)
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten = event.target.value;
      axios
        .get("/api/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahan(event) {
      this.form.kecamatan = event.target.value;
      axios
        .get("/api/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getOption() {
      if (this.form.provinsi) {
        axios
          .get("/api/daerah/kabupaten/" + this.form.provinsi)
          .then((response) => {
            this.opsiKabupaten = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kabupaten) {
        axios
          .get("/api/daerah/kecamatan/" + this.form.kabupaten)
          .then((response) => {
            this.opsiKecamatan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kecamatan) {
        axios
          .get("/api/daerah/kelurahan/" + this.form.kecamatan)
          .then((response) => {
            this.opsiKelurahan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    resetForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.formValidate = [];
      this.link = "/api/kelahiran/create";
      this.loading = false;
      this.actionBtn = "Simpan";
    },
  },
};
</script>