export function hitungUsia(tgl_imunisasi, tgl_lahir) {
  const today = new Date(tgl_imunisasi);
  const birthdate = new Date(tgl_lahir);
  let age = today.getFullYear() - birthdate.getFullYear();
  const monthDiff = today.getMonth() - birthdate.getMonth();
  const dayDiff = today.getDate() - birthdate.getDate();

  if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
    age--;
    const months = 12 - birthdate.getMonth() + today.getMonth();
    const days =
      new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate() -
      birthdate.getDate() +
      today.getDate();
    return age + " Tahun " + months + " Bulan " + days + " Hari";
  } else if (dayDiff < 0) {
    const months = monthDiff - 1;
    const days =
      new Date(today.getFullYear(), today.getMonth(), 0).getDate() -
      birthdate.getDate() +
      today.getDate();
    return age + " Tahun " + months + " Bulan " + days + " Hari";
  } else {
    return age + " Tahun " + monthDiff + " Bulan " + dayDiff + " Hari";
  }
}
