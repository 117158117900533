<template>
  <div class="row">
    <div class="col-xl-3 col-md-6">
      <div class="widget widget-stats bg-teal-transparent-7" id="formulir-01">
        <div class="stats-icon"><i class="fa fa-desktop"></i></div>
        <div class="stats-info">
          <strong class="f-s-20">Formulir 01</strong>
          <h4>Data Dasar Puskesmas</h4>
        </div>
        <div class="stats-link">
          <router-link
            :to="'/laporan-tahunan/formulir-01'"
            class="disabled-link"
            >Buka Formulir <i class="fa fa-arrow-alt-circle-right"></i
          ></router-link>
        </div>
      </div>
      <b-tooltip
        target="formulir-01"
        title="Laporan Tahunan Data Dasar Puskesmas"
      >
      </b-tooltip>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="widget widget-stats bg-teal-transparent-7" id="formulir-20">
        <div class="stats-icon"><i class="fa fa-desktop"></i></div>
        <div class="stats-info">
          <strong class="f-s-20">Formulir 20</strong>
          <h4>Program</h4>
        </div>
        <div class="stats-link" disable>
          <router-link
            :to="'/laporan-tahunan/formulir-20'"
            class="disabled-link"
            >Buka Formulir <i class="fa fa-arrow-alt-circle-right"></i
          ></router-link>
        </div>
      </div>
      <b-tooltip target="formulir-20" title="Laporan Tahunan Program">
      </b-tooltip>
    </div>
  </div>
</template>

<style scoped>
.disabled-link {
  cursor: not-allowed;
  pointer-events: none;
}
</style>

<script>
import { BTooltip } from "bootstrap-vue";

export default {
  components: {
    BTooltip,
  },
};
</script>
