import axios from "axios";
import Swal from "sweetalert2";

const username = "sipdinkespalu";
const password = "CeHc5E8Quv6hN1Hr";
const account = btoa(`${username}:${password}`);

axios.interceptors.request.use((config) => {
  // config.baseURL = "http://localhost/api/sip-dinkes";
  // config.baseURL = "https://dinkes.palukota.go.id/rest/";
  config.baseURL = "https://api.smartpuskesmas.web.id";
  // config.baseURL = "http://api.test/sip-dinkes";

  config.headers.Authorization = `Basic ${account}`;

  return config;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const {
      status,
      data
    } = error.response;

    if (status >= 500) {
      Swal.fire({
        icon: "error",
        title: "",
        text: data.message,
      });
    }

    return Promise.reject(error);
  }
);