<template>
  <div class="vertical-box-column">
    <div class="vertical-box">
      <div class="wrapper">
        <div class="btn-toolbar">
          <div class="btn-group btn-block m-r-5">
            <a
              href="javascript:;"
              class="btn btn-default btn-sm p-l-auto p-r-auto"
              style="font-weight: bold"
            >
              Data Kematian Di Puskesmas
            </a>
          </div>
        </div>
      </div>

      <div class="vertical-box-row bg-white">
        <div class="vertical-box-cell">
          <div class="vertical-box-inner-cell">
            <vue-custom-scrollbar class="height-full">
              <form
                id="myForm"
                @submit.prevent="postData"
                enctype="multipart/form-data"
              >
                <div class="container mt-3">
                  <div class="col-md-12">
                    <input
                      type="text"
                      v-model="form.id_kematian"
                      hidden
                      name="id_kematian"
                    />
                    <input
                      type="text"
                      v-model="form.kode_kematian"
                      hidden
                      name="kode_kematian"
                    />
                    <input
                      type="text"
                      v-model="form.kode_icd_ten"
                      hidden
                      name="kode_icd_ten"
                    />
                    <input
                      type="text"
                      v-model="form.diagnosa"
                      hidden
                      name="diagnosa"
                    />
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="nik">NIK</label>
                    <div class="col-md-6">
                      <input
                        v-model="form.nik"
                        type="text"
                        class="form-control"
                        name="nik"
                        placeholder="NIK"
                        :class="{
                          'is-invalid': formValidate.nik,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="nama"
                      >Nama</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.nama"
                        type="text"
                        class="form-control"
                        name="nama"
                        placeholder="Nama"
                        :class="{
                          'is-invalid': formValidate.nama,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Tanggal Lahir</label>
                    <div class="col-md-3">
                      <input
                        v-model="form.tanggal_lahir"
                        name="tanggal_lahir"
                        type="date"
                        class="form-control m-b-5"
                        placeholder="Tanggal Lahir"
                        :class="{
                          'is-invalid': formValidate.tanggal_lahir,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="jenis_kelamin"
                      >Jenis Kelamin</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.jenis_kelamin"
                        name="jenis_kelamin"
                        class="form-control"
                        :class="{
                          'is-invalid': formValidate.jenis_kelamin,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option value="Laki-Laki">Laki-Laki</option>
                        <option value="Perempuan">Perempuan</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="provinsi"
                      >Provinsi</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.provinsi"
                        class="form-control default-select2"
                        name="provinsi"
                        v-on:change="getKabupaten($event)"
                        :class="{
                          'is-invalid': formValidate.provinsi,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="provinsi in opsiProvinsi"
                          :key="provinsi.nama_provinsi"
                          :value="provinsi.nama_provinsi"
                        >
                          {{ provinsi.nama_provinsi }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="kabupaten"
                      >Kabupaten</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.kabupaten"
                        name="kabupaten"
                        class="form-control m-b-5"
                        v-on:change="getKecamatan($event)"
                        :class="{
                          'is-invalid': formValidate.kabupaten,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="kabupaten in opsiKabupaten"
                          :key="kabupaten.nama_kabupaten"
                          :value="kabupaten.nama_kabupaten"
                        >
                          {{ kabupaten.nama_kabupaten }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="kecamatan"
                      >Kecamatan</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.kecamatan"
                        name="kecamatan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.kecamatan,
                        }"
                        v-on:change="getKelurahan($event)"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="kecamatan in opsiKecamatan"
                          :key="kecamatan.nama_kecamatan"
                          :value="kecamatan.nama_kecamatan"
                        >
                          {{ kecamatan.nama_kecamatan }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="kelurahan"
                      >Kelurahan</label
                    >
                    <div class="col-md-3">
                      <select
                        v-model="form.kelurahan"
                        name="kelurahan"
                        class="form-control m-b-5"
                        :class="{
                          'is-invalid': formValidate.kelurahan,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option
                          v-for="kelurahan in opsiKelurahan"
                          :key="kelurahan.nama_kelurahan"
                          :value="kelurahan.nama_kelurahan"
                        >
                          {{ kelurahan.nama_kelurahan }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label">RT/RW</label>
                    <div class="col-md-3">
                      <div class="input-group">
                        <input
                          v-model="form.rt"
                          type="text"
                          name="rt"
                          class="form-control"
                          placeholder="RT"
                          style="text-align: center"
                          :class="{
                            'is-invalid': formValidate.rt,
                          }"
                        />
                        <input
                          v-model="form.rw"
                          type="text"
                          name="rw"
                          class="form-control"
                          placeholder="RW"
                          style="text-align: center"
                          :class="{
                            'is-invalid': formValidate.rw,
                          }"
                        />
                      </div>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Nama Jalan</label>
                    <div class="col-md-8">
                      <input
                        v-model="form.nama_jalan"
                        name="nama_jalan"
                        type="text"
                        class="form-control m-b-5"
                        placeholder="Nama Jalan"
                        :class="{
                          'is-invalid': formValidate.nama_jalan,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4">Nomor Rumah</label>
                    <div class="col-md-3">
                      <input
                        v-model="form.nomor_rumah"
                        name="nomor_rumah"
                        type="text"
                        class="form-control m-b-5"
                        placeholder="Nomor Rumah"
                        style="text-align: center"
                        :class="{
                          'is-invalid': formValidate.nomor_rumah,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4"
                      >Tanggal Meninggal</label
                    >
                    <div class="col-md-3">
                      <input
                        v-model="form.tanggal_meninggal"
                        name="tanggal_meninggal"
                        type="date"
                        class="form-control m-b-5"
                        placeholder="Tanggal Meninggal"
                        :class="{
                          'is-invalid': formValidate.tanggal_meninggal,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4"
                      >Tempat Meninggal</label
                    >
                    <div class="col-md-3">
                      <input
                        v-model="form.tempat_meninggal"
                        name="tempat_meninggal"
                        type="text"
                        class="form-control m-b-5"
                        placeholder="Tempat Meninggal"
                        :class="{
                          'is-invalid': formValidate.tempat_meninggal,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row">
                    <label class="col-form-label col-md-4"
                      >Sebab Dasar Kematian</label
                    >
                    <div class="col-md-8">
                      <input
                        v-model="search"
                        type="text"
                        name="search"
                        class="form-control"
                        placeholder="Masukkan Diagnosa/Kode ICD 10"
                        @input="handleInput"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4"></label>
                    <div class="col-md-8">
                      <table v-if="showICD" style="width: 100%">
                        <tr
                          v-for="icd in rows"
                          v-bind:key="icd.code"
                          @click="setICD(icd.code, icd.name_id)"
                        >
                          <td>
                            <a
                              class="btn btn-sm btn-block btn-default"
                              style="text-align: left"
                              href="javascript:;"
                              >{{ icd.code }} : {{ icd.name_id }}</a
                            >
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </form>
            </vue-custom-scrollbar>
          </div>
        </div>
      </div>

      <div class="wrapper clearfix">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn btn-teal"
            @click="postData()"
            :disabled="this.loading"
          >
            <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
            {{ this.actionBtn }}
          </button>

          <!-- <button type="submit" class="btn btn-teal btn-block">
            <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
            Simpan
          </button> -->
        </div>
        <div class="m-t-5 text-inverse f-w-600">
          <button type="submit" class="btn btn-default" @click="resetForm()">
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";
// import eventBus from "../../../plugins/eventBus";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_kematian: "",
        kode_kematian: "",
        nik: "",
        nama: "",
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        rt: "",
        rw: "",
        nama_jalan: "",
        nomor_rumah: "",
        tanggal_lahir: "",
        jenis_kelamin: "",
        tanggal_meninggal: "",
        tempat_meninggal: "",
        diagnosa: "",
        kode_icd_ten: "",
      },
      link: "/api/kematian/create",
      opsiProvinsi: [],
      opsiKabupaten: [],
      opsiKecamatan: [],
      opsiKelurahan: [],
      formValidate: [],
      loading: false,
      search: "",
      actionBtn: "Simpan",
      timeoutId: null,
      showICD: false,
      rows: [],
      // detail: "",
    };
  },
  props: {
    detail: {
      type: Object,
    },
    editForm: {
      type: Boolean,
    },
  },
  mounted() {
    if (this.editForm) {
      this.form = { ...this.detail };
      this.getOption();
      this.link = "/api/kematian/update";
      this.actionBtn = "Update";
      this.search = this.form.kode_icd_ten + " : " + this.form.diagnosa;
    } else {
      this.resetForm();
    }
    this.getProvinsi();
  },
  created() {
    this.$parent.$on("openForm", this.openFormHandler);
  },
  methods: {
    // openFormHandler(detail) {
    //   this.detail = detail;
    //   console.log(detail);
    //   this.openForm = true;
    // },
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }
      // const formElement = document.getElementById("myForm");
      // const formData = new FormData(formElement);
      // console.log(this.form);

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.resetForm();
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getProvinsi() {
      axios
        .get("/api/daerah/provinsi")
        .then((response) => {
          this.opsiProvinsi = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKabupaten(event) {
      this.form.provinsi = event.target.value;
      axios
        .get("/api/daerah/kabupaten/" + event.target.value)
        .then((response) => {
          this.opsiKabupaten = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKecamatan(event) {
      this.form.kabupaten = event.target.value;
      axios
        .get("/api/daerah/kecamatan/" + event.target.value)
        .then((response) => {
          this.opsiKecamatan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getKelurahan(event) {
      this.form.kecamatan = event.target.value;
      axios
        .get("/api/daerah/kelurahan/" + event.target.value)
        .then((response) => {
          this.opsiKelurahan = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getOption() {
      if (this.form.provinsi) {
        axios
          .get("/api/daerah/kabupaten/" + this.form.provinsi)
          .then((response) => {
            this.opsiKabupaten = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kabupaten) {
        axios
          .get("/api/daerah/kecamatan/" + this.form.kabupaten)
          .then((response) => {
            this.opsiKecamatan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
      if (this.form.kecamatan) {
        axios
          .get("/api/daerah/kelurahan/" + this.form.kecamatan)
          .then((response) => {
            this.opsiKelurahan = response.data.data;
          })
          .catch((error) => {
            console.log(error);
            this.error = error.message;
          });
      }
    },
    handleInput() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.searchTimeOut();
      }, 500);
    },
    searchTimeOut() {
      // this.rows = [];
      const formData = new FormData();
      formData.append("search", this.search);
      axios
        .post("/api/icdten/search/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.showICD = true;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setICD(kode, diagnosa) {
      this.form.kode_icd_ten = kode;
      this.form.diagnosa = diagnosa;
      this.search = kode + " : " + diagnosa;
      this.showICD = false;
      this.rows = [];
    },
    resetForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.formValidate = [];
      this.link = "/api/kematian/create";
      this.loading = false;
      this.actionBtn = "Simpan";
    },
  },
};
</script>