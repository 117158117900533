<template>
  <div class="vertical-box-column">
    <div class="vertical-box">
      <div class="wrapper">
        <div class="btn-toolbar">
          <div class="btn-group btn-block m-r-5">
            <a
              href="javascript:;"
              class="btn btn-default btn-sm p-l-auto p-r-auto"
              style="font-weight: bold"
            >
              Data Kesehatan Lingkungan
            </a>
          </div>
        </div>
      </div>

      <div class="vertical-box-row bg-white">
        <div class="vertical-box-cell">
          <div class="vertical-box-inner-cell">
            <vue-custom-scrollbar class="height-full">
              <form
                id="myForm"
                @submit.prevent="postData"
                enctype="multipart/form-data"
              >
                <div class="container mt-3">
                  <div class="col-md-12">
                    <input
                      type="text"
                      hidden
                      v-model="form.id_inspeksi_kesling"
                      name="id_inspeksi_kesling"
                    />
                    <input
                      type="text"
                      hidden
                      v-model="form.kode_inspeksi_kesling"
                      name="kode_inspeksi_kesling"
                    />
                  </div>

                  <div class="form-group row m-b-15">
                    <label
                      class="col-md-4 col-form-label push-right"
                      for="jenis_sarana"
                      >Jenis Sarana</label
                    >
                    <div class="col-md-4">
                      <select
                        v-model="form.jenis_sarana"
                        name="jenis_sarana"
                        class="form-control"
                        :class="{
                          'is-invalid': formValidate.jenis_sarana,
                        }"
                      >
                        <option value="">Pilih</option>
                        <option value="1">Sarana Air Minum</option>
                        <option value="2">
                          Tempat Pengelolaan Makanan (TPM)
                        </option>
                        <option value="3">Tempat-Tempat Umum (TTU)</option>
                        <option value="4">Rumah</option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="nama_sarana"
                      >Nama Sarana</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.nama_sarana"
                        type="text"
                        class="form-control"
                        name="nama_sarana"
                        placeholder="Nama Sarana"
                        :class="{
                          'is-invalid': formValidate.nama_sarana,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="pemilik_sarana"
                      >Pemilik Sarana</label
                    >
                    <div class="col-md-6">
                      <input
                        v-model="form.pemilik_sarana"
                        type="text"
                        class="form-control"
                        name="pemilik_sarana"
                        placeholder="Nama Pemilik Sarana"
                        :class="{
                          'is-invalid': formValidate.pemilik_sarana,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="alamat_sarana"
                      >Alamat</label
                    >
                    <div class="col-md-8">
                      <input
                        v-model="form.alamat_sarana"
                        type="text"
                        class="form-control"
                        name="alamat_sarana"
                        placeholder="Alamat Lengkap"
                        :class="{
                          'is-invalid': formValidate.alamat_sarana,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-form-label col-md-4"
                      >Hasil Inspeksi</label
                    >
                    <div class="col-md-3">
                      <div class="input-group">
                        <select
                          v-model="form.hasil_inspeksi"
                          class="form-control"
                          name="hasil_inspeksi"
                          :class="{
                            'is-invalid': formValidate.hasil_inspeksi,
                          }"
                        >
                          <option value="">Pilih</option>
                          <option value="1">Memenuhi Syarat</option>
                          <option value="0">Tidak Memenuhi Syarat</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </vue-custom-scrollbar>
          </div>
        </div>
      </div>

      <div class="wrapper clearfix">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn btn-teal"
            @click="postData()"
            :disabled="this.loading"
          >
            <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
            {{ this.actionBtn }}
          </button>
        </div>
        <div class="m-t-5 text-inverse f-w-600">
          <button type="submit" class="btn btn-default" @click="resetForm()">
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_inspeksi_kesling: "",
        kode_inspeksi_kesling: "",
        jenis_sarana: "",
        nama_sarana: "",
        pemilik_sarana: "",
        alamat_sarana: "",
        hasil_inspeksi: "",
      },
      link: "/api/inspeksi_kesling/create",
      formValidate: [],
      loading: false,
      actionBtn: "Simpan",
      // detail: "",
    };
  },
  props: {
    detail: {
      type: Object,
    },
    editForm: {
      type: Boolean,
    },
  },
  mounted() {
    if (this.editForm) {
      this.form = { ...this.detail };
      this.link = "/api/inspeksi_kesling/update";
      this.actionBtn = "Update";
    } else {
      this.resetForm();
    }
  },
  created() {
    this.$parent.$on("openForm", this.openFormHandler);
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.resetForm();
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    resetForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.formValidate = [];
      this.link = "/api/inspeksi_kesling/create";
      this.loading = false;
      this.actionBtn = "Simpan";
    },
  },
};
</script>