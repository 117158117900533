<template>
  <div class="vertical-box with-grid inbox">
    <div class="vertical-box-column width-200 hidden-xs">
      <div class="vertical-box">
        <div class="wrapper">
          <div class="d-flex align-items-center justify-content-center">
            <b-button
              v-b-toggle.emailNav
              variant="inverse"
              class="btn-sm mr-auto d-block d-lg-none"
            >
              <i class="fa fa-ellipsis-h"></i>
            </b-button>
            <a
              href="javascript:;"
              class="btn btn-default btn-sm p-l-40 p-r-40"
              style="font-weight: bold"
            >
              Formulir 01
            </a>
          </div>
        </div>
        <b-collapse
          class="vertical-box-row collapse d-lg-table-row"
          id="emailNav"
        >
          <div class="vertical-box-cell">
            <div class="vertical-box-inner-cell">
              <div data-scrollbar="true" data-height="100%">
                <div class="wrapper p-0">
                  <div class="nav-title"><b>MENU</b></div>
                  <ul class="nav nav-inbox">
                    <li v-bind:class="viewLaporan ? 'active' : ''">
                      <a
                        href="javascript:;"
                        @click="showLaporan()"
                        v-bind:class="viewLaporan ? 'text-primary' : ''"
                        ><i class="fa fa-envelope fa-fw m-r-5"></i> Laporan
                      </a>
                    </li>
                    <li v-bind:class="viewData ? 'active' : ''">
                      <a
                        href="javascript:;"
                        @click="showData()"
                        v-bind:class="viewData ? 'text-primary' : ''"
                        ><i class="fa fa-file fa-fw m-r-5"></i> Data</a
                      >
                    </li>
                    <li v-bind:class="viewForm ? 'active' : ''">
                      <a
                        href="javascript:;"
                        @click="showForm()"
                        v-bind:class="viewForm ? 'text-primary' : ''"
                        ><i class="fa fa-edit fa-fw m-r-5"></i> Form</a
                      >
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>
    </div>

    <Laporan v-if="viewLaporan"></Laporan>
    <Data v-if="viewData"></Data>
    <Form v-if="viewForm" :detail="detail" :editForm="editForm"></Form>
  </div>
</template>

<script>
import PageOptions from "../../../config/PageOptions.vue";
import Laporan from "./Laporan.vue";
import Data from "./Data.vue";
import Form from "./Form.vue";
import { eventBus } from "../../../plugins/eventBus";

export default {
  components: {
    Laporan,
    Data,
    Form,
  },
  data() {
    PageOptions.pageContentFullWidth = true;
    PageOptions.pageContentFullHeight = true;

    return {
      viewLaporan: true,
      viewData: false,
      viewForm: false,
      editForm: false,
      detail: {},
    };
  },
  mounted() {
    eventBus.$on("openForm", (detail) => {
      this.detail = detail;
      this.editForm = true;
      this.viewLaporan = false;
      this.viewData = false;
      this.viewForm = true;
    });
  },
  beforeDestroy() {
    eventBus.$off("openForm");
  },
  methods: {
    openForm() {
      this.viewLaporan = false;
      this.viewData = false;
      this.viewForm = true;
    },
    showLaporan() {
      this.viewLaporan = true;
      this.viewData = false;
      this.viewForm = false;
    },
    showData() {
      this.viewLaporan = false;
      this.viewData = true;
      this.viewForm = false;
    },
    showForm() {
      this.detail = {};
      this.editForm = false;
      this.viewLaporan = false;
      this.viewData = false;
      this.viewForm = true;
    },
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageContentFullWidth = false;
    PageOptions.pageContentFullHeight = false;
    next();
  },
};
</script>