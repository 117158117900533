<template>
  <div class="register register-with-news-feed">
    <div class="news-feed bg-orange-transparent-1">
      <div
        class="news-image"
        style="background-image: url(/sip/assets/img/bg-login.webp)"
      ></div>
    </div>
    <div
      class="right-content bg-orange-transparent-1"
      style="
        background-image: url(/sip/assets/img/login-3.webp);
        background-size: cover;
      "
    >
      <div class="login-header">
        <div class="brand">
          <img
            src="/sip/assets/img/logo-text.png"
            alt="Logo"
            style="width: 90%"
          />
        </div>
      </div>
      <h1 class="register-header">
        <small>Mohon mengisi data anda dengan baik dan benar.</small>
      </h1>
      <div class="register-content">
        <form
          @submit.prevent="postData"
          id="formPeserta"
          enctype="multipart/form-data"
        >
          <div class="row">
            <div class="col-md-6">
              <div class="form-group">
                <label for="nomor_identitas"
                  >NIK <span class="text-danger">*</span></label
                >
                <masked-input
                  v-model="form.nomor_identitas"
                  type="text"
                  name="nomor_identitas"
                  class="form-control m-b-5"
                  mask="1111111111111111"
                  :class="{
                    'is-invalid': formValidate.nomor_identitas,
                  }"
                  placeholder="NIK"
                ></masked-input>
                <small
                  class="text-danger"
                  v-html="formValidate.nomor_identitas"
                ></small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="nama_lengkap"
                  >Nama Lengkap <span class="text-danger">*</span></label
                >
                <input
                  v-model="form.nama_lengkap"
                  type="text"
                  name="nama_lengkap"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.nama_lengkap,
                  }"
                  placeholder="Nama Lengkap"
                />
                <small
                  class="text-danger"
                  v-html="formValidate.nama_lengkap"
                ></small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="email"
                  >Email <span class="text-danger">*</span></label
                >
                <input
                  v-model="form.email"
                  type="email"
                  name="email"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.email,
                  }"
                  placeholder="Alamat Email"
                />
                <small class="text-danger" v-html="formValidate.email"></small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label class="control-label"
                  >Nomor HP/WA <span class="text-danger">*</span></label
                >
                <masked-input
                  v-model="form.no_handphone"
                  type="text"
                  name="no_handphone"
                  class="form-control m-b-5"
                  mask="1111-1111-1111"
                  :class="{
                    'is-invalid': formValidate.no_handphone,
                  }"
                  placeholder="xxxx-xxxx-xxxx"
                ></masked-input>
                <small
                  class="text-danger"
                  v-html="formValidate.no_handphone"
                ></small>
              </div>
            </div>

            <div class="col-md-6 col-xs-6">
              <div class="form-group">
                <label for="kategori_faskes"
                  >Kategori Faskes <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.kategori_faskes"
                  name="kategori_faskes"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.kategori_faskes,
                  }"
                  v-on:change="getFaskes($event)"
                >
                  <option value="">Pilih</option>
                  <option value="01">Puskesmas</option>
                  <option value="02">Klinik Pratama</option>
                  <option value="03">Klinik Utama</option>
                  <option value="04">Praktik Mandiri Dokter</option>
                  <option value="05">Praktik Mandiri Dokter Gigi</option>
                  <option value="06">Praktik Mandiri Bidan</option>
                  <option value="07">Praktik Mandiri Perawat</option>
                  <option value="08">Apotek</option>
                  <option value="09">Optik</option>
                  <option value="10">Laboratorium Klinik</option>
                </select>
                <small
                  class="text-danger"
                  v-html="formValidate.kategori_faskes"
                ></small>
              </div>
            </div>

            <div class="col-md-6 col-xs-6">
              <div class="form-group">
                <label for="faskes"
                  >Faskes <span class="text-danger">*</span></label
                >
                <select
                  v-model="form.faskes"
                  name="faskes"
                  class="form-control m-b-5"
                  :class="{
                    'is-invalid': formValidate.faskes,
                  }"
                >
                  <option value="">Pilih</option>
                  <option
                    v-for="faskes in opsiFaskes"
                    :key="faskes.kode_faskes"
                    :value="faskes.kode_faskes"
                  >
                    {{ faskes.nama_faskes }}
                  </option>
                </select>
                <small class="text-danger" v-html="formValidate.faskes"></small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="password"
                  >Password <span class="text-danger">*</span></label
                >
                <input
                  type="password"
                  v-model="form.password"
                  class="form-control"
                  placeholder="Password"
                  :class="{
                    'is-invalid': formValidate.password,
                  }"
                />
                <small
                  class="text-danger"
                  v-html="formValidate.password"
                ></small>
              </div>
            </div>

            <div class="col-md-6">
              <div class="form-group">
                <label for="confirm_password"
                  >Ulangi Password <span class="text-danger">*</span></label
                >
                <input
                  type="password"
                  v-model="form.confirm_password"
                  class="form-control"
                  placeholder="Ulangi Password"
                  :class="{
                    'is-invalid': formValidate.confirm_password,
                  }"
                />
                <small
                  class="text-danger"
                  v-html="formValidate.confirm_password"
                ></small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label for="foto">Foto</label>
                <div>
                  <a
                    href="#"
                    class="btn btn-primary m-1"
                    role="button"
                    @click.prevent="showFileChooser"
                    :class="{
                      'btn-danger': notImage || formValidate.image,
                    }"
                  >
                    <i class="fa fa-fw fa-image"></i> Pilih/Ambil Foto
                  </a>
                  <br />
                  <small class="text-danger" v-if="this.notImage"
                    >Harap gunakan foto yang benar</small
                  >
                  <small
                    class="text-danger"
                    v-html="formValidate.image"
                  ></small>
                </div>
              </div>
            </div>
            <div class="col-md-6">
              <input
                style="display: none"
                ref="input"
                type="file"
                name="image"
                accept="image/*"
                @change="setImage"
              />

              <div class="row">
                <div class="col-md-12 cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      ref="cropper"
                      :aspect-ratio="1 / 1"
                      :src="imgSrc"
                      preview=".preview"
                      style="width: 100%; border-radius: 5px; overflow: hidden"
                    />
                    <div v-if="this.imgSrc">
                      <div class="btn-group mt-1">
                        <a
                          href="#"
                          class="btn btn-sm btn-primary"
                          role="button"
                          @click.prevent="rotate(90)"
                        >
                          <i class="fa fa-redo"></i>
                        </a>
                        <a
                          href="#"
                          class="btn btn-sm btn-primary"
                          role="button"
                          @click.prevent="rotate(-90)"
                        >
                          <i class="fa fa-redo fa-flip-horizontal"></i>
                        </a>
                        <a
                          href="#"
                          class="btn btn-sm btn-primary"
                          role="button"
                          @click.prevent="cropImage"
                        >
                          <i class="fa fa-crop"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-4 cropped-image p-4">
              <img
                v-if="cropImg"
                :src="cropImg"
                alt="Cropped Image"
                style="width: 100%; border-radius: 50%"
              />
              <div v-else class="crop-placeholder" />
            </div>
          </div>

          <div class="register-buttons m-t-30">
            <button
              type="button"
              class="btn btn-primary btn-block btn-lg"
              @click="postData()"
              :disabled="this.loading"
            >
              <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
              Registrasi
            </button>
          </div>
          <div class="m-t-20 m-b-20 p-b-20 text-inverse">
            Sudah memiliki akun? klik
            <router-link to="/login/">Login</router-link> untuk login.
          </div>
          <hr />
          <p class="text-center text-grey-darker">
            &copy; Dinkes Kota Palu All Right Reserved 2022
          </p>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import PageOptions from "../config/PageOptions.vue";
import axios from "axios";
import Swal from "sweetalert2";
import MaskedInput from "vue-masked-input";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";

export default {
  components: {
    MaskedInput,
    VueCropper,
  },
  data() {
    return {
      form: {
        nama_lengkap: "",
        nama_panggilan: "",
        no_handphone: "",
        email: "",
        nomor_identitas: "",
        kategori_faskes: "",
        faskes: "",
        password: "",
        confirm_password: "",
        foto: "",
        foto_crop: "",
      },
      opsiFaskes: [],
      imgSrc: "",
      cropImg: "",
      formValidate: [],
      loading: false,
      notImage: false,
    };
  },
  mounted() {},
  created() {
    PageOptions.pageEmpty = true;
    document.body.className = "bg-white";
  },
  beforeRouteLeave(to, from, next) {
    PageOptions.pageEmpty = false;
    document.body.className = "";
    next();
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();

      if (this.cropImg.includes("http") || this.cropImg == "") {
        // formData.append("foto", "");
      } else {
        const blob = this.dataURLtoBlob(this.cropImg);
        formData.append("foto", blob, "foto.png");
      }
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post("/v1/auth/registrasi/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          // console.log(response.data.message);
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            // console.log(response.data.message);
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              confirmButtonColor: "#3085d6",
              confirmButtonText: "OK!",
            }).then(() => {
              this.$router.push("/login");
            });
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getFaskes(event) {
      this.form.kategori_faskes = event.target.value;
      axios
        .get("/v1/faskes/" + event.target.value)
        .then((response) => {
          this.opsiFaskes = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    dataURLtoBlob(dataURL) {
      const base64Data = dataURL.split(",")[1];
      const byteCharacters = atob(base64Data);
      const byteNumbers = new Array(byteCharacters.length);

      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      return new Blob([byteArray], { type: "image/png" });
    },

    cropImage() {
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },

    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        this.notImage = true;
        // alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.notImage = false;
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
        };

        reader.readAsDataURL(file);
      } else {
        this.notImage = true;
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
    },
  },
};
</script>