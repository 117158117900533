<template>
  <div class="vertical-box-column">
    <div class="vertical-box">
      <div class="wrapper">
        <div class="btn-toolbar">
          <div class="btn-group m-r-5">
            <button class="btn btn-sm btn-white" @click="fetchData()">
              <i class="fa fa-sync"></i>
            </button>
          </div>
          <div class="btn-group m-r-5">
            <select
              v-model="form.tahun_filter"
              class="form-control form-control-sm"
              name="tahun_filter"
              v-on:change="getFilter()"
            >
              <option value="">-Pilih Tahun-</option>
              <option v-for="year in years" :value="year" :key="year">
                {{ year }}
              </option>
            </select>
          </div>
          <div class="btn-group">
            <select
              v-model="form.bulan_filter"
              class="form-control form-control-sm"
              name="bulan_filter"
              v-on:change="getFilter()"
            >
              <option value="">-Pilih Bulan-</option>
              <option
                v-for="month in months"
                :value="month.value"
                :key="month.value"
              >
                {{ month.label }}
              </option>
            </select>
          </div>
        </div>
      </div>

      <div class="vertical-box-row bg-white">
        <div class="vertical-box-cell">
          <div class="vertical-box-inner-cell">
            <vue-custom-scrollbar class="height-full">
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :lineNumbers="true"
                :search-options="{
                  enabled: true,
                  placeholder: 'Search this table',
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                  perPageDropdownEnabled: false,
                  position: 'bottom',
                  nextLabel: '',
                  prevLabel: '',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All',
                }"
              >
                <div slot="table-actions">
                  <a
                    href="javascript:;"
                    class="btn btn-sm btn-primary"
                    @click="showModal"
                  >
                    <i class="fa fa-plus"></i>
                    <span class="d-none d-sm-inline"> Buat Pengajuan</span>
                  </a>
                </div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'bulan'">
                    {{ formatBulan(props.row.bulan_pengajuan) }}
                  </span>
                  <span v-if="props.column.field == 'status'">
                    <span
                      class="btn btn-xs btn-primary"
                      v-if="props.row.status_pengajuan == '0'"
                      >Diajukan</span
                    >
                    <span
                      class="btn btn-xs btn-success"
                      v-if="props.row.status_pengajuan == '1'"
                      >Disetujui</span
                    >
                  </span>
                  <span v-if="props.column.field == 'action'">
                    <button
                      class="btn btn-sm btn-danger"
                      v-on:click="confirmDelete(props.row.id_pengajuan)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </vue-custom-scrollbar>
          </div>
        </div>
      </div>

      <div class="wrapper clearfix">
        <div class="btn-group pull-right"></div>
        <div class="m-t-5 text-inverse f-w-600"></div>
      </div>
    </div>

    <!-- modal -->
    <b-modal v-model="modalVisible" @hide="beforeModalClose">
      <template #modal-header>
        <strong>Form Pengajuan</strong>
      </template>
      <div>
        <div class="form-group">
          <label for="tahun_pengajuan">Tahun :</label>
          <select
            v-model="form.tahun_pengajuan"
            class="form-control"
            :class="{
              'is-invalid': formValidate.tahun_pengajuan,
            }"
            name="tahun_pengajuan"
            required
          >
            <option value="">-Pilih Tahun-</option>
            <option v-for="year in years" :value="year" :key="year">
              {{ year }}
            </option>
          </select>
        </div>
        <div class="form-group">
          <label for="bulan_pengajuan">Bulan :</label>
          <select
            v-model="form.bulan_pengajuan"
            class="form-control"
            :class="{
              'is-invalid': formValidate.bulan_pengajuan,
            }"
            name="bulan_pengajuan"
            required
          >
            <option value="">-Pilih Bulan-</option>
            <option
              v-for="month in months"
              :value="month.value"
              :key="month.value"
            >
              {{ month.label }}
            </option>
          </select>
        </div>
      </div>
      <template #modal-footer>
        <b-button @click="closeModal">Batal</b-button>
        <b-button @click="postLaporan" variant="primary">
          <i v-if="loading" class="fas fa-circle-notch fa-spin"></i>
          Ajukan Laporan Bulanan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>


<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import sipData from "../../../plugins/sipData";

export default {
  mixins: [sipData],
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  data() {
    return {
      loading: false,
      form: {
        tahun_pengajuan: "",
        bulan_pengajuan: "",
        tahun_filter: "",
        bulan_filter: "",
      },
      columns: [
        {
          label: "Tahun",
          field: "tahun_pengajuan",
          width: "140px",
          thClass: "text-nowrap text-center",
          tdClass: "text-nowrap text-center",
        },
        {
          label: "Bulan",
          field: "bulan",
          width: "210px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Status",
          field: "status",
          width: "100px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "50px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
      modalVisible: false,
      conditionMet: false,
      years: [],
      months: [
        { value: "01", label: "Januari" },
        { value: "02", label: "Februari" },
        { value: "03", label: "Maret" },
        { value: "04", label: "April" },
        { value: "05", label: "Mei" },
        { value: "06", label: "Juni" },
        { value: "07", label: "Juli" },
        { value: "08", label: "Agustus" },
        { value: "09", label: "September" },
        { value: "10", label: "Oktober" },
        { value: "11", label: "November" },
        { value: "12", label: "Desember" },
      ],
      formValidate: [],
    };
  },
  mounted() {
    this.getYears();
    // this.getMonths();
    this.fetchData();
  },
  methods: {
    showModal() {
      this.modalVisible = true;
      this.conditionMet = false;
    },
    postLaporan() {
      this.loading = true;
      const formData = new FormData();

      formData.append("tahun_pengajuan", this.form.tahun_pengajuan);
      formData.append("bulan_pengajuan", this.form.bulan_pengajuan);
      formData.append("formulir", "formulir-03");

      axios
        .post("/api/pengajuan", formData, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            this.modalVisible = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            this.modalVisible = false;
            this.conditionMet = true;
            setTimeout(() => {
              Swal.close();
              this.resetForm();
              this.fetchData();
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    closeModal() {
      this.resetForm();
      this.modalVisible = false;
      this.conditionMet = true;
    },
    beforeModalClose(evt) {
      if (!this.conditionMet) {
        evt.preventDefault();
      }
    },
    formatTahun(dateTime) {
      return moment(dateTime).format("YYYY");
    },
    formatBulan(dateTime) {
      const bulanMap = {
        "01": "Januari",
        "02": "Februari",
        "03": "Maret",
        "04": "April",
        "05": "Mei",
        "06": "Juni",
        "07": "Juli",
        "08": "Agustus",
        "09": "September",
        10: "Oktober",
        11: "November",
        12: "Desember",
      };
      return bulanMap[dateTime] || "Bulan tidak valid";
    },
    fetchData() {
      this.rows = [];
      axios
        .get("/api/pengajuan/", {
          headers: {
            token: this.userToken,
          },
          params: {
            formulir: "formulir-03",
          },
        })
        .then((response) => {
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/pengajuan/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                this.fetchData();
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },

    getFilter() {
      const formData = new FormData();

      formData.append("tahun", this.form.tahun_filter);
      formData.append("bulan", this.form.bulan_filter);
      formData.append("formulir", "formulir-03");

      axios
        .post("/api/pengajuan/filter", formData, {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    getYears() {
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i > currentYear - 5; i--) {
        this.years.push(i);
      }
      this.form.tahun_pengajuan = currentYear;
    },

    resetForm() {
      this.form.tahun_pengajuan = "";
      this.form.bulan_pengajuan = "";
      this.formValidate = [];
    },
  },
};
</script>