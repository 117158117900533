<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-teal text-light">
          <h3 class="panel-title bold">Form Register</h3>
        </div>
        <form @submit.prevent="postData" enctype="multipart/form-data">
          <div class="panel-body">
            <div class="container mt-3">
              <div class="col-md-12">
                <input
                  type="text"
                  v-model="form.id_register"
                  hidden
                  name="id_register"
                />
              </div>

              <div class="form-group row m-b-15">
                <label class="col-form-label col-md-4">Tanggal, Jam</label>
                <div class="col-md-6">
                  <div class="input-group">
                    <input
                      v-model="form.tanggal"
                      name="tanggal"
                      type="date"
                      class="form-control m-b-5"
                      placeholder="Tanggal Lahir"
                      :class="{
                        'is-invalid': formValidate.tanggal,
                      }"
                    />
                    <input
                      v-model="form.jam"
                      name="jam"
                      type="time"
                      class="form-control m-b-5"
                      placeholder="Jam Lahir"
                      :class="{
                        'is-invalid': formValidate.jam,
                      }"
                    />
                  </div>
                </div>
              </div>

              <div class="form-group row m-b-15">
                <label class="col-md-4 col-form-label" for="nik">NIK</label>
                <div class="col-md-6">
                  <input
                    v-model="search.pasien"
                    type="text"
                    class="form-control"
                    name="nik"
                    placeholder="Masukkan NIK/Nama Pasien"
                    @input="handlePasien"
                    :class="{
                      'is-invalid': formValidate.nik,
                    }"
                  />
                </div>
                <div class="col-md-2">
                  <router-link
                    to="/pasien/create"
                    class="btn btn-primary btn-block"
                  >
                    Pasien Baru
                  </router-link>
                </div>
              </div>

              <div class="form-group row m-b-15" v-if="showPasien">
                <label class="col-form-label col-md-4"></label>
                <div class="col-md-8">
                  <table style="width: 100%">
                    <tr
                      v-for="pasien in rows.pasien"
                      v-bind:key="pasien.nik"
                      @click="setPasien(pasien.nik, pasien.nama)"
                    >
                      <td>
                        <a
                          class="btn btn-sm btn-block btn-default"
                          style="text-align: left"
                          href="javascript:;"
                          >{{ pasien.nik }} : {{ pasien.nama }}</a
                        >
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="form-group row m-b-15">
                <label class="col-md-4 col-form-label push-right" for="gejala"
                  >Gejala, Penyebab Penyakit Atau Kondisi Pasien</label
                >
                <div class="col-md-8">
                  <input
                    list="listGejala"
                    v-model="form.gejala"
                    name="gejala"
                    class="form-control m-b-5"
                    placeholder="Gejala, Penyebab Penyakit Atau Kondisi Pasien"
                    :class="{
                      'is-invalid': formValidate.gejala,
                    }"
                  />
                  <datalist id="listGejala">
                    <option value="">Pilih</option>
                    <option
                      v-for="data in opsiGejala"
                      :key="data.gejala"
                      :value="data.gejala"
                    >
                      {{ data.gejala }}
                    </option>
                  </datalist>
                </div>
              </div>

              <div class="form-group row m-b-15">
                <label class="col-form-label col-md-4"
                  >Anamnesa & Pemeriksaan</label
                >
                <div class="col-md-8">
                  <textarea
                    rows.icd="3"
                    v-model="form.anamnesa"
                    name="anamnesa"
                    type="text"
                    class="form-control m-b-5"
                    placeholder="Anamnesa & Pemeriksaan"
                    :class="{
                      'is-invalid': formValidate.anamnesa,
                    }"
                  >
                  </textarea>
                </div>
              </div>

              <div class="form-group row">
                <label class="col-form-label col-md-4"
                  >Diagnosa & Kode ICD</label
                >
                <div class="col-md-8">
                  <input
                    v-model="search.icd"
                    type="text"
                    name="search"
                    class="form-control"
                    placeholder="Masukkan Diagnosa/Kode ICD 10"
                    @input="handleICD"
                  />
                </div>
              </div>

              <div class="form-group row m-b-15" v-if="showICD">
                <label class="col-form-label col-md-4"></label>
                <div class="col-md-8">
                  <table style="width: 100%">
                    <tr
                      v-for="icd in rows.icd"
                      v-bind:key="icd.code"
                      @click="setICD(icd.code, icd.name_id)"
                    >
                      <td>
                        <a
                          class="btn btn-sm btn-block btn-default"
                          style="text-align: left"
                          href="javascript:;"
                          >{{ icd.code }} : {{ icd.name_id }}</a
                        >
                      </td>
                    </tr>
                  </table>
                </div>
              </div>

              <div class="form-group row m-b-15">
                <label class="col-form-label col-md-4"
                  >Perencanaan Layanan</label
                >
                <div class="col-md-8">
                  <textarea
                    rows.icd="3"
                    v-model="form.tindakan"
                    name="tindakan"
                    type="text"
                    class="form-control m-b-5"
                    placeholder="Perencanaan Layanan"
                    :class="{
                      'is-invalid': formValidate.tindakan,
                    }"
                  >
                  </textarea>
                </div>
              </div>

              <div class="form-group row m-b-15">
                <label class="col-form-label col-md-4">Petugas</label>
                <div class="col-md-8">
                  <input
                    v-model="form.petugas"
                    name="petugas"
                    type="text"
                    class="form-control m-b-5"
                    placeholder="Nama Petugas"
                    :class="{
                      'is-invalid': formValidate.petugas,
                    }"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="panel-footer">
            <a href="javascript:window.history.go(-1);" class="btn btn-default"
              >Batal</a
            >
            <button
              type="button"
              class="btn btn-teal pull-right"
              @click="postData()"
              :disabled="this.loading"
            >
              <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
              {{ $route.params.id ? "Update" : "Submit" }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>


<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_register: "",
        kode_register: "",
        pasien: "",
        tanggal: "",
        jam: "",
        anamnesa: "",
        gejala: "",
        diagnosa: "",
        tindakan: "",
        petugas: "",
      },
      search: {
        icd: "",
        pasien: "",
        gejala: "",
      },
      rows: {
        icd: [],
        pasien: [],
        gejala: [],
      },
      link: "/api/register/create",
      opsiGejala: [],
      formValidate: [],
      loading: false,
      showUserAccess: true,
      timeoutId: null,
      showICD: false,
      showPasien: false,
    };
  },
  mounted() {
    this.getGejala();
    const id = this.$route.params.id;
    if (id) {
      this.link = "/api/register/update";
      this.form.id_register = id;
      this.fetchData();
    } else {
      this.form.id = "";
    }
  },
  methods: {
    postData() {
      this.loading = true;

      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.$router.go(-1);
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchData() {
      axios
        .get("/api/register/detail/" + this.form.id_register)
        .then((response) => {
          for (let key in response.data.data) {
            this.form[key] = response.data.data[key];
          }
          this.search.icd =
            response.data.data["diagnosa"] +
            " : " +
            response.data.data["ket_diagnosa"];
          this.search.pasien =
            response.data.data["pasien"] +
            " : " +
            response.data.data["nama_pasien"];
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getGejala() {
      axios
        .get("/api/gejala")
        .then((response) => {
          this.opsiGejala = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    handleICD() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.searchICD();
      }, 500);
    },
    handlePasien() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(() => {
        this.searchPasien();
      }, 500);
    },
    searchICD() {
      const formData = new FormData();
      formData.append("search", this.search.icd);
      axios
        .post("/api/icdten/search/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows.icd = response.data.data || [];
          if (this.rows.icd.length <= 0) {
            this.showICD = false;
          } else {
            this.showICD = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    searchPasien() {
      const formData = new FormData();
      formData.append("search", this.search.pasien);
      axios
        .post("/api/pasien/search/", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows.pasien = response.data.data || [];
          if (this.rows.pasien.length <= 0) {
            this.showPasien = false;
          } else {
            this.showPasien = true;
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    setICD(kode, diagnosa) {
      this.form.diagnosa = kode;
      this.search.icd = kode + " : " + diagnosa;
      this.showICD = false;
      this.rows.icd = [];
    },
    setPasien(nik, nama) {
      this.form.pasien = nik;
      this.search.pasien = nik + " : " + nama;
      this.showPasien = false;
      this.rows.pasien = [];
    },
  },
};
</script>
