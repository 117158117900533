<template>
  <div class="vertical-box-column">
    <div class="vertical-box">
      <div class="wrapper">
        <div class="btn-toolbar">
          <div class="btn-group m-r-5">
            <button class="btn btn-sm btn-white" @click="fetchData()">
              <i class="fa fa-sync"></i>
            </button>
          </div>
          <div class="btn-group m-r-5" v-if="showDetail">
            <button
              class="btn btn-sm btn-warning m-r-5"
              v-on:click="closeDetail()"
            >
              <i class="fa fa-times"></i>
            </button>
          </div>
        </div>
      </div>

      <div class="vertical-box-row bg-white">
        <div class="vertical-box-cell">
          <div class="vertical-box-inner-cell">
            <vue-custom-scrollbar class="height-full">
              <b-collapse v-model="showDetail" class="container mt-3">
                <table class="table">
                  <tr>
                    <td>Nama Puskesmas</td>
                    <td style="width: 20px">:</td>
                    <td>{{ detail.nama_puskesmas }}</td>
                  </tr>

                  <tr>
                    <td>Tanggal dan Jam Lahir</td>
                    <td style="width: 20px">:</td>
                    <td>
                      {{ formatIndo(detail.tanggal_lahir) }} Jam
                      {{ formatJam(detail.jam_lahir) }}
                    </td>
                  </tr>

                  <tr>
                    <td>Nama Bayi</td>
                    <td style="width: 20px">:</td>
                    <td>{{ detail.nama_bayi }}</td>
                  </tr>

                  <tr>
                    <td>No. KK</td>
                    <td style="width: 20px">:</td>
                    <td>{{ detail.no_kk }}</td>
                  </tr>

                  <tr>
                    <td>Jenis Kelamain</td>
                    <td style="width: 20px">:</td>
                    <td>{{ detail.jenis_kelamin }}</td>
                  </tr>

                  <tr>
                    <td>Nama Orang Tua</td>
                    <td style="width: 20px">:</td>
                    <td>{{ detail.nama_ayah }} & {{ detail.nama_ibu }}</td>
                  </tr>

                  <tr>
                    <td>Alamat Lengkap</td>
                    <td style="width: 20px">:</td>
                    <td>
                      {{ detail.nama_jalan }} No. {{ detail.nomor_rumah }} RT
                      {{ detail.rt }} RW {{ detail.rw }} {{ detail.kelurahan }},
                      {{ detail.kecamatan }}, {{ detail.kabupaten }},
                      {{ detail.provinsi }}
                    </td>
                  </tr>

                  <tr>
                    <td>BB/TB</td>
                    <td style="width: 20px">:</td>
                    <td>
                      {{ detail.berat_badan }} Kg / {{ detail.tinggi_badan }} cm
                    </td>
                  </tr>

                  <tr>
                    <td>Normal/Rujuk</td>
                    <td style="width: 20px">:</td>
                    <td>{{ detail.normal_rujuk }}</td>
                  </tr>
                </table>
              </b-collapse>
              <vue-good-table
                :columns="columns"
                :rows="rows"
                :lineNumbers="true"
                :search-options="{
                  enabled: true,
                  placeholder: 'Search this table',
                }"
                :pagination-options="{
                  enabled: true,
                  mode: 'records',
                  perPage: 10,
                  perPageDropdownEnabled: false,
                  position: 'bottom',
                  nextLabel: '',
                  prevLabel: '',
                  ofLabel: 'of',
                  pageLabel: 'page',
                  allLabel: 'All',
                }"
              >
                <div slot="table-actions"></div>
                <template slot="table-row" slot-scope="props">
                  <span v-if="props.column.field == 'tgl_lahir'">
                    <span>{{ formatIndo(props.row.tanggal_lahir) }}</span>
                  </span>

                  <span v-if="props.column.field == 'action'">
                    <button
                      class="btn btn-sm btn-success m-r-5"
                      v-on:click="fetchDetail(props.row.id_kelahiran)"
                    >
                      <i class="fa fa-search"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-primary m-r-5"
                      v-on:click="openForm(props.row.id_kelahiran)"
                    >
                      <i class="fa fa-pencil-alt"></i>
                    </button>
                    <button
                      class="btn btn-sm btn-danger"
                      v-on:click="confirmDelete(props.row.id_kelahiran)"
                    >
                      <i class="fa fa-trash-alt"></i>
                    </button>
                  </span>
                  <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                  </span>
                </template>
              </vue-good-table>
            </vue-custom-scrollbar>
          </div>
        </div>
      </div>

      <div class="wrapper clearfix">
        <div class="btn-group pull-right">
          <!-- <button class="btn btn-white btn-sm">
            <i class="fa fa-chevron-left"></i>
          </button>
          <button class="btn btn-white btn-sm">
            <i class="fa fa-chevron-right"></i>
          </button> -->
        </div>
        <div class="m-t-5 text-inverse f-w-600"></div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import sipData from "../../../plugins/sipData";
import { eventBus } from "../../../plugins/eventBus";

export default {
  mixins: [sipData],
  data() {
    return {
      viewForm: true,
      columns: [
        {
          label: "Tanggal Lahir",
          field: "tgl_lahir",
          thClass: "text-nowrap text-center",
          tdClass: "text-nowrap text-center",
        },
        {
          label: "No. KK",
          field: "no_kk",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Nama Bayi",
          field: "nama_bayi",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Normal/Rujuk",
          field: "normal_rujuk",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "50px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      showDetail: false,
      detail: {
        id_kelahiran: "",
        kode_kelahiran: "",
        nama_puskesmas: "",
        nama_bayi: "",
        jenis_kelamin: "",
        nama_ayah: "",
        nama_ibu: "",
        no_kk: "",
        provinsi: "",
        kabupaten: "",
        kecamatan: "",
        kelurahan: "",
        rt: "",
        rw: "",
        nama_jalan: "",
        nomor_rumah: "",
        tanggal_lahir: "",
        jam_lahir: "",
        bulan_kehamilan: "",
        hari_kehamilan: "",
        berat_badan: "",
        tinggi_badan: "",
        normal_rujuk: "",
      },
    };
  },
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.rows = [];
      axios
        .get("/api/kelahiran/", {
          headers: {
            token: this.userToken,
          },
          params: {
            formulir: "formulir-19",
          },
        })
        .then((response) => {
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    fetchDetail(id) {
      axios
        .get("/api/kelahiran/detail/" + id)
        .then((response) => {
          // console.log(response);
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
      this.showDetail = true;
    },

    openForm(id) {
      axios
        .get("/api/kelahiran/detail/" + id)
        .then((response) => {
          for (let key in response.data.data) {
            this.detail[key] = response.data.data[key];
          }
          eventBus.$emit("openForm", this.detail);
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },

    closeDetail() {
      this.showDetail = false;
    },

    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },

    deleteData(id) {
      try {
        axios
          .get("/api/kelahiran/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                showConfirmButton: false,
              });
              setTimeout(() => {
                Swal.close();
                this.fetchData();
              }, 700);
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },

    formatIndo(dateTime) {
      return moment(dateTime).format("DD MMMM YYYY");
    },

    formatJam(dateTime) {
      return dateTime.slice(0, 5);
    },
  },
};
</script>