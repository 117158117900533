<template>
  <div class="vertical-box-column">
    <div class="vertical-box">
      <div class="wrapper">
        <div class="btn-toolbar">
          <div class="btn-group btn-block m-r-5">
            <a
              href="javascript:;"
              class="btn btn-default btn-sm p-l-auto p-r-auto"
              style="font-weight: bold"
            >
              Data permintaan_obat
            </a>
          </div>
        </div>
      </div>

      <div class="vertical-box-row bg-white">
        <div class="vertical-box-cell">
          <div class="vertical-box-inner-cell">
            <vue-custom-scrollbar class="height-full">
              <form
                id="myForm"
                @submit.prevent="postData"
                enctype="multipart/form-data"
              >
                <div class="container mt-3">
                  <div class="col-md-12">
                    <input
                      type="text"
                      hidden
                      v-model="form.id_permintaan_obat"
                      name="id_permintaan_obat"
                    />
                    <input
                      type="text"
                      hidden
                      v-model="form.kode_permintaan_obat"
                      name="kode_permintaan_obat"
                    />
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="tahun"
                      >Tahun</label
                    >
                    <div class="col-md-2">
                      <select
                        v-model="form.tahun"
                        class="form-control"
                        :class="{
                          'is-invalid': formValidate.tahun,
                        }"
                        name="tahun"
                        required
                      >
                        <option value="">-Pilih Tahun-</option>
                        <option v-for="year in years" :value="year" :key="year">
                          {{ year }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="bulan"
                      >Bulan</label
                    >
                    <div class="col-md-2">
                      <select
                        v-model="form.bulan"
                        class="form-control"
                        :class="{
                          'is-invalid': formValidate.bulan,
                        }"
                        name="bulan"
                      >
                        <option value="">-Pilih Bulan-</option>
                        <option
                          v-for="month in months"
                          :value="month.value"
                          :key="month.value"
                        >
                          {{ month.label }}
                        </option>
                      </select>
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="kode_obat"
                      >Kode Obat</label
                    >
                    <div class="col-md-3">
                      <input
                        v-model="form.kode_obat"
                        type="text"
                        class="form-control"
                        name="kode_obat"
                        :class="{
                          'is-invalid': formValidate.kode_obat,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="nama_obat"
                      >Nama Obat</label
                    >
                    <div class="col-md-4">
                      <input
                        v-model="form.nama_obat"
                        type="text"
                        class="form-control"
                        name="nama_obat"
                        :class="{
                          'is-invalid': formValidate.nama_obat,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="satuan"
                      >Satuan</label
                    >
                    <div class="col-md-2">
                      <input
                        v-model="form.satuan"
                        type="text"
                        class="form-control"
                        name="satuan"
                        :class="{
                          'is-invalid': formValidate.satuan,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="stok_awal"
                      >Stok Awal</label
                    >
                    <div class="col-md-2">
                      <input
                        v-model="form.stok_awal"
                        type="number"
                        class="form-control"
                        name="stok_awal"
                        :class="{
                          'is-invalid': formValidate.stok_awal,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="penerimaan"
                      >Penerimaan</label
                    >
                    <div class="col-md-2">
                      <input
                        v-model="form.penerimaan"
                        type="number"
                        class="form-control"
                        name="penerimaan"
                        :class="{
                          'is-invalid': formValidate.penerimaan,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="pemakaian"
                      >Pemakaian</label
                    >
                    <div class="col-md-2">
                      <input
                        v-model="form.pemakaian"
                        type="number"
                        class="form-control"
                        name="pemakaian"
                        :class="{
                          'is-invalid': formValidate.pemakaian,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="stok_optimum"
                      >Stok Optimum</label
                    >
                    <div class="col-md-2">
                      <input
                        v-model="form.stok_optimum"
                        type="number"
                        class="form-control"
                        name="stok_optimum"
                        :class="{
                          'is-invalid': formValidate.stok_optimum,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="permintaan"
                      >Permintaan</label
                    >
                    <div class="col-md-2">
                      <input
                        v-model="form.permintaan"
                        type="number"
                        class="form-control"
                        name="permintaan"
                        :class="{
                          'is-invalid': formValidate.permintaan,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="pemberian"
                      >Pemberian</label
                    >
                    <div class="col-md-2">
                      <input
                        v-model="form.pemberian"
                        type="number"
                        class="form-control"
                        name="pemberian"
                        :class="{
                          'is-invalid': formValidate.pemberian,
                        }"
                      />
                    </div>
                  </div>

                  <div class="form-group row m-b-15">
                    <label class="col-md-4 col-form-label" for="keterangan"
                      >Keterangan</label
                    >
                    <div class="col-md-8">
                      <input
                        v-model="form.keterangan"
                        type="text"
                        class="form-control"
                        name="keterangan"
                        :class="{
                          'is-invalid': formValidate.keterangan,
                        }"
                      />
                    </div>
                  </div>
                </div>
              </form>
            </vue-custom-scrollbar>
          </div>
        </div>
      </div>

      <div class="wrapper clearfix">
        <div class="btn-group pull-right">
          <button
            type="button"
            class="btn btn-teal"
            @click="postData()"
            :disabled="this.loading"
          >
            <i v-if="this.loading" class="fas fa-circle-notch fa-spin"></i>
            {{ this.actionBtn }}
          </button>
        </div>
        <div class="m-t-5 text-inverse f-w-600">
          <button type="submit" class="btn btn-default" @click="resetForm()">
            Reset
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import sipData from "../../../plugins/sipData";

export default {
  mixins: [sipData],
  data() {
    return {
      form: {
        id_permintaan_obat: "",
        kode_permintaan_obat: "",
        bulan: "",
        tahun: "",
        kode_obat: "",
        nama_obat: "",
        satuan: "",
        stok_awal: "",
        penerimaan: "",
        pemakaian: "",
        stok_optimum: "",
        permintaan: "",
        pemberian: "",
        keterangan: "",
      },
      link: "/api/permintaan_obat/create",
      years: [],
      months: [
        { value: "01", label: "Januari" },
        { value: "02", label: "Februari" },
        { value: "03", label: "Maret" },
        { value: "04", label: "April" },
        { value: "16", label: "Mei" },
        { value: "06", label: "Juni" },
        { value: "07", label: "Juli" },
        { value: "08", label: "Agustus" },
        { value: "09", label: "September" },
        { value: "10", label: "Oktober" },
        { value: "11", label: "November" },
        { value: "12", label: "Desember" },
      ],
      formValidate: [],
      loading: false,
      actionBtn: "Simpan",
      // detail: "",
    };
  },
  props: {
    detail: {
      type: Object,
    },
    editForm: {
      type: Boolean,
    },
  },
  mounted() {
    this.getYears();
    if (this.editForm) {
      this.form = { ...this.detail };
      this.link = "/api/permintaan_obat/update";
      this.actionBtn = "Update";
    } else {
      this.resetForm();
    }
  },
  created() {
    this.$parent.$on("openForm", this.openFormHandler);
  },
  methods: {
    postData() {
      this.loading = true;
      const formData = new FormData();
      for (let key in this.form) {
        formData.append(key, this.form[key]);
      }

      axios
        .post(this.link, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            token: this.userToken,
          },
        })
        .then((response) => {
          if (response.data.validasi == false) {
            this.loading = false;
            this.formValidate = response.data.message;
            // console.log(this.formValidate);
          } else {
            this.loading = false;
            Swal.fire({
              title: response.data.title,
              text: response.data.message,
              icon: response.data.icon,
              showCancelButton: false,
              showConfirmButton: false,
            });
            setTimeout(() => {
              Swal.close();
              if (response.data.status) {
                this.resetForm();
              }
            }, 700);
          }
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    getYears() {
      const currentYear = new Date().getFullYear();
      for (let i = currentYear; i > currentYear - 5; i--) {
        this.years.push(i);
      }
      this.form.tahun_pengajuan = currentYear;
    },
    resetForm() {
      for (let key in this.form) {
        this.form[key] = "";
      }
      this.formValidate = [];
      this.link = "/api/permintaan_obat/create";
      this.loading = false;
      this.actionBtn = "Simpan";
    },
  },
};
</script>