import Vue from "vue";
import VueRouter from "vue-router";

import axios from "axios";
import Swal from "sweetalert2";

Vue.use(VueRouter);

import Dashboard from "../pages/Dashboard.vue";
import Login from "../pages/Login.vue";
import Registrasi from "../pages/Registrasi.vue";

import Profil from "../pages/profil/Profil.vue";

import Pengguna from "../pages/users/Index.vue";
import PenggunaForm from "../pages/users/Form.vue";

import Pasien from "../pages/pasien/Index.vue";
import PasienForm from "../pages/pasien/Form.vue";
import PasienDetail from "../pages/pasien/Detail.vue";

import Register from "../pages/register/Index.vue";
import RegisterForm from "../pages/register/Form.vue";
import RegisterDetail from "../pages/register/Detail.vue";

import Tahunan from "../pages/dashboard/Tahunan.vue";
import Bulanan from "../pages/dashboard/Bulanan.vue";
import Mingguan from "../pages/dashboard/Mingguan.vue";

import Formulir01 from "../pages/laporan/form-01/Index.vue";
import Formulir02 from "../pages/laporan/form-03/Index.vue";
import Formulir03 from "../pages/laporan/form-03/Index.vue";
import Formulir04 from "../pages/laporan/form-05/Index.vue";
import Formulir05 from "../pages/laporan/form-05/Index.vue";
import Formulir06 from "../pages/laporan/form-03/Index.vue";
import Formulir07 from "../pages/laporan/form-03/Index.vue";
import Formulir08 from "../pages/laporan/form-03/Index.vue";
import Formulir09 from "../pages/laporan/form-05/Index.vue";
import Formulir10 from "../pages/laporan/form-19/Index.vue";
import Formulir11 from "../pages/laporan/form-03/Index.vue";
import Formulir12 from "../pages/laporan/form-03/Index.vue";
import Formulir13 from "../pages/laporan/form-03/Index.vue";
import Formulir14 from "../pages/laporan/form-05/Index.vue";
import Formulir15 from "../pages/laporan/form-15/Index.vue";
import Formulir16 from "../pages/laporan/form-16/Index.vue";
import Formulir17 from "../pages/laporan/form-19/Index.vue";
import Formulir18 from "../pages/laporan/form-19/Index.vue";
import Formulir19 from "../pages/laporan/form-19/Index.vue";
import Formulir20 from "../pages/laporan/form-19/Index.vue";

const routes = [
  {
    path: "/",
    redirect: "/dashboard/",
  },
  { path: "*", redirect: "/dashboard/" },
  { path: "/dashboard", component: Dashboard },
  { path: "/login", component: Login },
  { path: "/registrasi", component: Registrasi },
  { path: "/pengguna", component: Pengguna },
  { path: "/pengguna/create", component: PenggunaForm },

  { path: "/profil", component: Profil },
  { path: "/profil/:page", component: Profil },

  { path: "/pasien", component: Pasien },
  { path: "/pasien/create", component: PasienForm },
  { path: "/pasien/update/:id", component: PasienForm },
  { path: "/pasien/detail/:id", component: PasienDetail },
  { path: "/register", component: Register },
  { path: "/register/create", component: RegisterForm },
  { path: "/register/update/:id", component: RegisterForm },
  { path: "/register/detail/:id", component: RegisterDetail },

  { path: "/laporan-tahunan", component: Tahunan },
  { path: "/laporan-bulanan", component: Bulanan },
  { path: "/laporan-mingguan", component: Mingguan },

  { path: "/laporan-tahunan/formulir-01", component: Formulir01 },
  { path: "/laporan-bulanan/formulir-02", component: Formulir02 },
  { path: "/laporan-bulanan/formulir-03", component: Formulir03 },
  { path: "/laporan-bulanan/formulir-04", component: Formulir04 },
  { path: "/laporan-bulanan/formulir-05", component: Formulir05 },
  { path: "/laporan-bulanan/formulir-06", component: Formulir06 },
  { path: "/laporan-bulanan/formulir-07", component: Formulir07 },
  { path: "/laporan-bulanan/formulir-08", component: Formulir08 },
  { path: "/laporan-bulanan/formulir-09", component: Formulir09 },
  { path: "/laporan-bulanan/formulir-10", component: Formulir10 },
  { path: "/laporan-bulanan/formulir-11", component: Formulir11 },
  { path: "/laporan-bulanan/formulir-12", component: Formulir12 },
  { path: "/laporan-bulanan/formulir-13", component: Formulir13 },
  { path: "/laporan-bulanan/formulir-14", component: Formulir14 },
  { path: "/laporan-bulanan/formulir-15", component: Formulir15 },
  { path: "/laporan-bulanan/formulir-16", component: Formulir16 },
  { path: "/laporan-mingguan/formulir-17", component: Formulir17 },
  { path: "/laporan-mingguan/formulir-18", component: Formulir18 },
  { path: "/laporan-bulanan/formulir-19", component: Formulir19 },
  { path: "/laporan-tahunan/formulir-20", component: Formulir20 },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoggedIn = localStorage.getItem("sipData");
  const userAceess = JSON.parse(isLoggedIn);

  const userLevel = userAceess ? userAceess.level : null;
  const requiredLevel = to.meta.requiredLevel;

  if (to.path !== "/login" && !isLoggedIn && to.path !== "/registrasi") {
    next("/login");
  } else if (to.path === "/login" && isLoggedIn) {
    next("/dashboard");
  } else if (requiredLevel && userLevel && userLevel !== requiredLevel) {
    if (!requiredLevel.includes(userLevel)) {
      next("/forbidden"); // Tambahkan halaman forbidden jika level pengguna tidak sesuai
    } else {
      next();
    }
  } else {
    next();
  }

  const userData = localStorage.getItem("sipData");
  if (userData) {
    const user = JSON.parse(userData);
    const token = user.token;

    axios
      .get("/v1/auth/status/", {
        headers: {
          token: token,
        },
      })
      .then((response) => {
        if (response.data.status === false) {
          Swal.fire({
            title: "Info",
            text: "Session Habis, Silahkan Login Kembali",
            icon: "info",
            showCancelButton: false,
            showConfirmButton: false,
          });
          setTimeout(() => {
            Swal.close();
            localStorage.removeItem("sipData");
            if (to.path !== "/login") {
              next("/login");
            }
          }, 3000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
});

export default router;
