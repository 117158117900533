<template>
  <div class="row">
    <div class="col-xl-3 col-md-6">
      <div class="widget widget-stats bg-teal-transparent-7" id="formulir-17">
        <div class="stats-icon"><i class="fa fa-desktop"></i></div>
        <div class="stats-info">
          <strong class="f-s-20">Formulir 17</strong>
          <h4>Penyakit Menular Potensi KLB</h4>
        </div>
        <div class="stats-link">
          <router-link
            :to="'/laporan-mingguan/formulir-17'"
            class="disabled-link"
            >Buka Formulir <i class="fa fa-arrow-alt-circle-right"></i
          ></router-link>
        </div>
      </div>
      <b-tooltip
        target="formulir-17"
        title="Laporan Mingguan Penyakit Menular Potensi KLB"
      >
      </b-tooltip>
    </div>
    <div class="col-xl-3 col-md-6">
      <div class="widget widget-stats bg-teal-transparent-7" id="formulir-18">
        <div class="stats-icon"><i class="fa fa-desktop"></i></div>
        <div class="stats-info">
          <strong class="f-s-20">Formulir 18</strong>
          <h4>KLB 24 Jam</h4>
        </div>
        <div class="stats-link">
          <router-link
            :to="'/laporan-mingguan/formulir-18'"
            class="disabled-link"
            >Buka Formulir <i class="fa fa-arrow-alt-circle-right"></i
          ></router-link>
        </div>
      </div>
      <b-tooltip target="formulir-18" title="Laporan KLB 24 Jam"> </b-tooltip>
    </div>
  </div>
</template>

<style scoped>
.disabled-link {
  cursor: not-allowed;
  pointer-events: none;
}
</style>

<script>
import { BTooltip } from "bootstrap-vue";

export default {
  components: {
    BTooltip,
  },
};
</script>
