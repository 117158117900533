<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-teal text-light">
          <h3 class="panel-title bold">Daftar Register</h3>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 9,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
          >
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default mr-2"
                >
                  <i v-if="loading" class="fa fa-sync fa-spin"></i>
                  <i v-else class="fa fa-sync"></i>
                </button>
                <router-link to="/register/create" class="btn btn-primary">
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Data</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'tgl'">
                {{ formatIndo(props.row.tanggal) }}
              </span>
              <span v-if="props.column.field == 'diagnosa_icd'">
                {{ props.row.diagnosa }} - {{ props.row.ket_diagnosa }}
              </span>
              <span v-if="props.column.field == 'action'">
                <div>
                  <router-link
                    :to="'/register/detail/' + props.row.id_register"
                    class="btn btn-sm btn-success m-r-5"
                  >
                    <i class="fa fa-search"></i>
                  </router-link>
                  <router-link
                    :to="'/register/update/' + props.row.id_register"
                    class="btn btn-sm btn-warning m-r-5"
                  >
                    <i class="fa fa-pencil-alt"></i>
                  </router-link>
                  <button
                    class="btn btn-sm btn-danger"
                    v-on:click="confirmDelete(props.row.id_register)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
import moment from "moment";
import sipData from "../../plugins/sipData";

export default {
  mixins: [sipData],
  created() {
    moment.updateLocale("en", {
      months: [
        "Januari",
        "Februari",
        "Maret",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Agustus",
        "September",
        "Oktober",
        "November",
        "Desember",
      ],
    });
  },
  data() {
    return {
      columns: [
        {
          label: "Tanggal",
          field: "tgl",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "NIK",
          field: "pasien",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Pasien",
          field: "nama_pasien",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },

        {
          label: "Diagnosa - ICD",
          field: "diagnosa_icd",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: [],
      loading: false,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.rows = [];
      this.loading = true;
      axios
        .get("/api/register/", {
          headers: {
            token: this.userToken,
          },
        })
        .then((response) => {
          this.rows = response.data.data;
          this.loading = false;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/api/register/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
    formatIndo(dateTime) {
      return moment(dateTime).format("DD MMMM YYYY");
    },
  },
};
</script>