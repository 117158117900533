<template>
  <div class="row">
    <div class="col-md-12">
      <div class="panel">
        <div class="panel-heading bg-teal text-light">
          <h3 class="panel-title bold">Daftar Pengguna</h3>
        </div>
        <div class="panel-body">
          <vue-good-table
            :columns="columns"
            :rows="rows"
            :lineNumbers="true"
            :search-options="{
              enabled: true,
              placeholder: 'Search this table',
            }"
            :pagination-options="{
              enabled: true,
              mode: 'records',
              perPage: 10,
              perPageDropdownEnabled: false,
              position: 'bottom',
              nextLabel: '',
              prevLabel: '',
              ofLabel: 'of',
              pageLabel: 'page',
              allLabel: 'All',
            }"
          >
            <div slot="table-actions">
              <div style="display: flex">
                <button
                  type="button"
                  v-on:click="fetchData()"
                  class="btn btn-default mr-2"
                >
                  <i class="fa fa-sync"></i>
                </button>
                <router-link to="/pengguna/create" class="btn btn-primary">
                  <i class="fa fa-plus d-md-none"></i>
                  <span class="d-none d-md-block">Tambah Pengguna</span>
                </router-link>
              </div>
            </div>
            <template slot="table-row" slot-scope="props">
              <span v-if="props.column.field == 'status'">
                <div class="switcher">
                  <input
                    type="checkbox"
                    :name="props.row.id_users"
                    :id="props.row.id_users"
                    :checked="parseInt(props.row.status_aktif)"
                    v-on:click="
                      toggleUser(props.row.id_users, props.row.status_aktif)
                    "
                  />
                  <label :for="props.row.id_users"></label>
                </div>
              </span>
              <span v-if="props.column.field == 'action'">
                <div>
                  <button
                    class="btn btn-sm btn-danger"
                    v-on:click="confirmDelete(props.row.id_users)"
                  >
                    <i class="fa fa-trash-alt"></i>
                  </button>
                </div>
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>
          </vue-good-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      columns: [
        {
          label: "Nama",
          field: "nama_lengkap",
          thClass: "text-center text-nowrap",
          tdClass: "text-nowrap",
        },
        {
          label: "Email",
          field: "email",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "No. HP",
          field: "no_handphone",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-nowrap",
        },
        {
          label: "Status Login",
          field: "status",
          width: "100px",
          thClass: "text-center text-nowrap",
          tdClass: "text-center text-wrap",
        },
        {
          label: "Aksi",
          field: "action",
          width: "100px",
          tdClass:
            "text-center f-s-14 f-w-600 text-inverse p-t-0 p-b-0 valign-middle text-nowrap",
          thClass: "text-center text-nowrap",
        },
      ],
      rows: null,
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      //   this.rows = [];
      axios
        .get("/v1/pengguna")
        .then((response) => {
          this.rows = response.data.data;
        })
        .catch((error) => {
          console.log(error);
          this.error = error.message;
        });
    },
    confirmDelete(id) {
      Swal.fire({
        title: "Apakah Anda Yakin ?",
        text: "Data Akan Terhapus Secara Permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "Batal",
        confirmButtonText: "Hapus!",
      }).then((result) => {
        if (result.value) {
          this.deleteData(id);
        }
      });
    },
    deleteData(id) {
      try {
        axios
          .get("/v1/pengguna/delete/" + id)
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                title: "Sukses",
                text: "Data Berhasil Dihapus",
                icon: "success",
                showCancelButton: false,
                confirmButtonColor: "#3085d6",
                confirmButtonText: "OK!",
              }).then(() => {
                this.fetchData();
              });
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },

    toggleUser(id, status) {
      var toggleLink = "/v1/pengguna/toggleOn/" + id;
      //   console.log(status);
      if (status == 1) {
        toggleLink = "/v1/pengguna/toggleOff/" + id;
      }
      try {
        axios
          .get(toggleLink)
          .then((response) => {
            // console.log(response.status);
            if (response.status === 201) {
              this.fetchData();
              // this.isChecked = true;
            }
          })
          .catch((error) => {
            console.log(error.message);
            this.error = error.message;
          });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
</script>